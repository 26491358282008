import { Route, Routes } from "react-router-dom";
import routes from "./Route";
import Layout from "../Layout/Layout";
import webservice from "../Service/webservice";
import { useEffect, useState } from "react";
import { AesDecrypt } from "../Service/crypto";
import ContactAdmin from "./ContactAdmin";


interface RouteType {
  path: string;
  Component: React.ComponentType;
}


const LayoutRoutes = () => {

  const login = localStorage.getItem("login");
  const [filteredRoutes, setFilteredRoutes] = useState<RouteType[]>([]);


  useEffect(() => {
    const fetchPermissions = async (data: any) => {
          
      try {
        let excludedPaths:any = [];
      
        try {
        const responses = await fetch(webservice + 'api/AppMenu/GetExcludedPaths', {
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          }
        });
   
        const responseDatas = await responses.json();
         excludedPaths = AesDecrypt(responseDatas);
        } catch (error) {
         excludedPaths =['/users/usersprofile', '/clientManagement/addClientadmin', '/clientManagement/editClientadmin', '/addvendoradmin', '/editvendoradmin', '/addTeam', '/editTeam', '/users/changepassword']

      }
        const response = await fetch(`${webservice}api/AppMenu/GetUrlPermission`, {
          method: 'POST',
          body: JSON.stringify({ data }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          }
        });

        const responseData = await response.json();
        if (excludedPaths.length > 0 && responseData.length > 0) {
        const filtered: any = routes
          .map(route => {
            if (excludedPaths.includes("/" + route.path)) {
              return { ...route };
            }

            const matchedPermission = responseData.find((permission: any) =>
              permission.Menu_url === "/" + route.path ||
              permission.Add_Url === "/" + route.path ||
              permission.Edit_Url === "/" + route.path ||
              route.path.includes(':')
            );

            if (
              matchedPermission &&
              (matchedPermission.Menu_Action.includes('view') ||
                matchedPermission.Menu_Action.includes('add') ||
                matchedPermission.Menu_Action.includes('edit'))
            ) {
              return { ...route };
            }

            return null;
          })
          .filter(route => route !== null);
        setFilteredRoutes(filtered);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      
    };

    const data = localStorage.id;

    if (data !== undefined && login) {
      fetchPermissions(data);
    }
  }, [login]);


  return (
    <>
      <Routes>
        {filteredRoutes.map(({ path, Component }: any, i) => (
          <Route element={<Layout />} key={i}>
            <Route path={path} element={Component} />
            <Route path={"*"} element={<ContactAdmin />} />
          </Route>
        ))}
      </Routes>
    </>
  );
};

export default LayoutRoutes;
